// import request from '../utils/request'




import {
	baseURL
} from '../main'
import axios from 'axios'
//API-1  用户管理

export let findAllUsers = (data) => {
	return axios({
		url: baseURL + "/customdata/user/findAllUsers",
		method: "post",
		data: data,
		headers: {
			Authorization: localStorage.getItem('token'),
		},
	})
}

//API-2 户外圈详情

export let getDetail = (data) => {
	return axios({
		url: baseURL + "/share/m-moment/getDetail",
		method: "get",
		params: data,
		headers: {
			Authorization: localStorage.getItem('token'),
		},
	})
}

// API-3 用户限制登录
export let loginBanPick = (data) => {
	return axios({
		url: baseURL + "/customdata/user/loginBanPick",
		method: 'post',
		data: data,
		headers: {
			Authorization: localStorage.getItem('token'),
		},
	})
}

// // API-4管理员登录
// // 
// export let Login = (data) => {
// 	return axios({
// 		url: baseURL + "/ucenter/p-user/Login",
// 		method: "post",
// 		data: data,

// 	})
// }

// API-5 用户详情
export let findUserDetails = (data) => {
	return axios({
		url: baseURL + "/customdata/user/findUserDetails",
		method: "post",
		data: data,
		headers: {
			Authorization: localStorage.getItem('token'),
		},
	})
}

// API-6  户外圈列表
export let findAllExpedition = (data) => {
	return axios({
		url: baseURL + "/customdata/expedition/findAllExpedition",
		method: "post",
		headers: {
			Authorization: localStorage.getItem('token'),
		},
		data: data,
	})
}

// API-7  户外圈详情
// 
export let findExpeditionDetails = (data) => {
	return axios({
		url: baseURL + '/customdata/expedition/findExpeditionDetails',
		method: "post",
		data: data,
		headers: {
			Authorization: localStorage.getItem('token'),
		},
	})
}
// API-8户外圈审核 
export let verifyExpedition = (data) => {
	return axios({
		url: baseURL + "/customdata/expedition/verifyExpedition",
		method: "post",
		data: data,
		headers: {
			Authorization: localStorage.getItem('token'),
		},
	})
}
console.log('获取到的token', localStorage.getItem('token'))
// API-9 新增户外圈
export let Issue = (data) => {
	return axios({
		url: baseURL + '/share/m-moment/issue',
		method: 'post',
		headers: {
			Authorization: localStorage.getItem('token'),
		},

		data: data
	})
}

// API-10投票活动列表
export let findVoteActs = (data) => {
	return axios({
		url: baseURL + "/customdata/vote-activity/findVoteActs",
		method: 'post',
		headers: {
			Authorization: localStorage.getItem('token'),
		},
		data: data
	})
}

// API-11 投票参赛者列表

export let findVoteActDetail = (data) => {
	return axios({
		url: baseURL + "/customdata/vote-activity/findVoteActDetail",
		method: 'post',
		headers: {
			Authorization: localStorage.getItem('token'),
		},
		data: data
	})
}

// API-12 投票活动审核
export let findVoteActDetails = (data) => {
	return axios({
		url: baseURL + "/customdata/vote-activity/findVoteActDetails",
		method: "post",
		headers: {
			Authorization: localStorage.getItem('token'),
		},
		data: data
	})
}

// API-13审核投票活动
export let auditVoteActivity = (data) => {
	return axios({
		url: baseURL + '/customdata/vote-activity/auditVoteActivity',
		method: 'post',
		headers: {
			Authorization: localStorage.getItem('token'),
		},
		data: data
	})
}
// API-14投票活动参赛者详情
export let voteContestantSelect = (data) => {
	return axios({
		url: baseURL + '/customdata/vote-option/voteContestantSelect',
		method: 'post',
		headers: {
			Authorization: localStorage.getItem('token'),
		},
		data: data
	})
}

// API-15 删除投票参赛者
export let voteContestantDelete = (data) => {
	return axios({
		url: baseURL + '/customdata/vote-option/voteContestantDelete',
		method: 'post',
		headers: {
			Authorization: localStorage.getItem('token'),
		},
		data: data
	})
}

// API- 16 修改参赛者
export let voteContestantUpdate = (data) => {
	return axios({
		url: baseURL + '/customdata/vote-option/voteContestantUpdate',
		method: 'post',
		headers: {
			Authorization: localStorage.getItem('token'),
		},
		data: data
	})
}

// API-17新增参赛者

export let voteContestantAdd = (data) => {
	return axios({
		url: baseURL + '/customdata/vote-option/voteContestantAdd',
		method: 'post',
		headers: {
			Authorization: localStorage.getItem('token'),
		},
		data: data
	})
}

// API-18 查看用户信息列表
export let userInfoList = (data) => {
	return axios({
		url: baseURL + "/customdata/userInfo/userInfoList",
		method: 'post',
		headers: {
			Authorization: localStorage.getItem('token'),
		},
		data: data
	})
}

// API-19 审核用户信息

export let checkUserInfo = (data) => {
	return axios({
		url: baseURL + "/customdata/userInfo/checkUserInfo",
		method: "post",
		headers: {
			Authorization: localStorage.getItem('token'),
		},
		data: data
	})
}

//API-20查看动态评论列表
export let sysCommentList = (data) => {
	return axios(({
		url: baseURL + "/customdata/expedition/sysCommentList",
		method: 'post',
		headers: {
			Authorization: localStorage.getItem('token'),
		},
		data: data
	}))
}

// API-21查看动态评论详情
export let commentDetail = (data) => {
	return axios({
		url: baseURL + "/customdata/expedition/commentDetail",
		method: 'post',
		headers: {
			Authorization: localStorage.getItem('token'),
		},
		data: data
	})
}

// API-22审核动态评论
export let verifyComment = (data) => {
	return axios({
		url: baseURL + "/customdata/expedition/verifyComment",
		method: 'post',
		headers: {
			Authorization: localStorage.getItem('token'),
		},
		data: data
	})
}
// API-23 彩虹之路评论列表查询
export let rainbowCommentList = (data) => {
	return axios({
		url: baseURL + '/customdata/rainbowWay/rainbowCommentList',
		method: 'post',
		headers: {
			Authorization: localStorage.getItem('token'),
		},
		data: data
	})
}
// API-24彩虹之路评论详情查询
export let rainbowCommentDetail = (data) => {
	return axios({
		url: baseURL + '/customdata/rainbowWay/rainbowCommentDetail',
		method: 'post',
		headers: {
			Authorization: localStorage.getItem('token'),
		},
		data: data
	})
}

// API-25彩虹之路评论审核
export let verifyRainbowComment = (data) => {
	return axios({
		url: baseURL + '/customdata/rainbowWay/verifyRainbowComment',
		method: 'post',
		headers: {
			Authorization: localStorage.getItem('token'),
		},
		data: data
	})
}

// API-26后台登录
export let Login = (data) => {
	return axios({
		url: baseURL + '/customdata/user-system/Login',
		method: 'post',
		data: data
	})
}

// API-27 删除户外圈
export let sysDeleteMoment = (data) => {
	return axios({
		url: baseURL + '/customdata/expedition/sysDeleteMoment',
		method: 'post',
		headers: {
			Authorization: localStorage.getItem('token'),
		},
		data: data
	})
}
// API-27 删除彩虹之路评论
export let rainDeleteComment = (data) => {
	return axios({
		url: baseURL + '/customdata/rainbowWay/sysDeleteComment',
		method: 'post',
		headers: {
			Authorization: localStorage.getItem('token'),
		},
		data: data
	})
}
// API-27 删除户外圈评论
export let sysDeleteComment = (data) => {
	return axios({
		url: baseURL + '/customdata/expedition/sysDeleteComment',
		method: 'post',
		headers: {
			Authorization: localStorage.getItem('token'),
		},
		data: data
	})
}

// API-28 任务列表

export let rainbowTaskList = (data) => {
	return axios({
		url: baseURL + "/customdata/rainbow-task/rainbowTaskList",
		method: "post",
		headers: {
			Authorization: localStorage.getItem('token'),
		},
		data: data
	})
}
// API-29任务详情

export let rainbowTaskDetail = (data) => {
	return axios({
		url: baseURL + "/customdata/rainbow-task/rainbowTaskDetail",
		method: "post",
		headers: {
			Authorization: localStorage.getItem('token'),
		},
		data: data
	})
}
// API-30 删除任务

export let deleteRTask = (data) => {
	return axios({
		url: baseURL + "/customdata/rainbow-task/deleteRTask",
		method: "post",
		headers: {
			Authorization: localStorage.getItem('token'),
		},
		data: data
	})
}
// API-31 新增报名活动

export let addSignActivity = (data) => {
	return axios({
		url: baseURL + "/customdata/sign-activity/addSignActivity",
		method: "post",
		headers: {
			Authorization: localStorage.getItem('token'),
		},
		data: data
	})
}

//  API-32新增任务
export let addRainbowTask = (data) => {
	return axios({
		url: baseURL + "/customdata/rainbow-task/addRainbowTask",
		method: "post",
		headers: {
			Authorization: localStorage.getItem('token'),
		},
		data: data
	})
}

//  API-33报名活动列表
export let findSignActs = (data) => {
	return axios({
		url: baseURL + "/customdata/sign-activity/findSignActs",
		method: "post",
		headers: {
			Authorization: localStorage.getItem('token'),
		},
		data: data
	})
}
//  API-34报名活动详情
export let findSignActDetail = (data) => {
	return axios({
		url: baseURL + "/customdata/sign-activity/findSignActDetail?aId=" + data,
		method: "get",
		headers: {
			Authorization: localStorage.getItem('token'),
		},

	})
}
//  API-35报名活动审核
export let auditSignActivity = (data) => {
	return axios({
		url: baseURL + "/customdata/sign-activity/auditSignActivity",
		method: "post",
		headers: {
			Authorization: localStorage.getItem('token'),
		},
		data: data
	})
}
//  API-36修改报名活动负责人
export let signPrincipalReplace = (data) => {
	return axios({
		url: baseURL + "/customdata/sign-activity/signPrincipalReplace",
		method: "post",
		headers: {
			Authorization: localStorage.getItem('token'),
		},
		data: data
	})
}

//  API-37修改报名活动信息
export let updateActivityTime = (data) => {
	return axios({
		url: baseURL + "/customdata/sign-activity/updateActivityTime",
		method: "post",
		headers: {
			Authorization: localStorage.getItem('token'),
		},
		data: data
	})
}
//  API-37活动绑定任务
export let associatedTasks = (data) => {
	return axios({
		url: baseURL + "/customdata/sign-activity/associatedTasks",
		method: "post",
		headers: {
			Authorization: localStorage.getItem('token'),
		},
		data: data
	})
}
//  API-38查看临时任务列表
export let onlySideMission = (data) => {
	return axios({
		url: baseURL + "/customdata/rainbow-task/onlySideMission",
		method: "post",
		headers: {
			Authorization: localStorage.getItem('token'),
		},
		data: data
	})
}
// API-39新增投票活动

export let addVoteAct = (data) => {
	return axios({
		url: baseURL + "/customdata/vote-activity/addVoteAct",
		method: "post",
		headers: {
			Authorization: localStorage.getItem('token'),
		},
		data: data
	})
}

//API-40 动态菜单栏接口
export let sysUserRights = (data) => {
	return axios({
		url: baseURL + "/customdata/user-system/sysUserRights",
		method: "post",
		headers: {
			Authorization: localStorage.getItem('token'),
		},
		data: data
	})
}

// API-41 查看角色列表
export let roleList = (data) => {
	return axios({
		url: baseURL + "/customdata/user-auth/roleList",
		method: "post",
		headers: {
			Authorization: localStorage.getItem('token'),
		},
		data: data
	})
}
// API-42新增角色
export let addSysRole = (data) => {
	return axios({
		url: baseURL + "/customdata/user-auth/addSysRole",
		method: "post",
		headers: {
			Authorization: localStorage.getItem('token'),
		},
		data: data
	})
}
// API-43修改角色
export let updateSysRole = (data) => {
	return axios({
		url: baseURL + "/customdata/user-auth/updateSysRole",
		method: "post",
		headers: {
			Authorization: localStorage.getItem('token'),
		},
		data: data
	})
}
// API-44删除角色
export let deleteRole = (data) => {
	return axios({
		url: baseURL + "/customdata/user-auth/deleteRole",
		method: "post",
		headers: {
			Authorization: localStorage.getItem('token'),
		},
		data: data
	})
}
// API-45给用户赋予角色
export let roleAssignment = (data) => {
	return axios({
		url: baseURL + "/customdata/user-auth/roleAssignment",
		method: "post",
		headers: {
			Authorization: localStorage.getItem('token'),
		},
		data: data
	})
}
// API-46取消用户的角色
export let cancelRoleAssignment = (data) => {
	return axios({
		url: baseURL + "/customdata/user-auth/cancelRoleAssignment",
		method: "post",
		headers: {
			Authorization: localStorage.getItem('token'),
		},
		data: data
	})
}
// API-47用户的角色列表
export let userRoleList = (data) => {
	return axios({
		url: baseURL + "/customdata/user-auth/userRoleList",
		method: "post",
		headers: {
			Authorization: localStorage.getItem('token'),
		},
		data: data
	})
}
// API-48角色的菜单权限列表
export let rolePermissionList = (data) => {
	return axios({
		url: baseURL + "/customdata/user-permission/rolePermissionList",
		method: "post",
		headers: {
			Authorization: localStorage.getItem('token'),
		},
		data: data
	})
}
// API-49给角色授权菜单
export let permissionGranted = (data) => {
	return axios({
		url: baseURL + "/customdata/user-permission/permissionGranted",
		method: "post",
		headers: {
			Authorization: localStorage.getItem('token'),
		},
		data: data
	})
}
// API-50角色的数据权限列表
export let rolePermissionDataList = (data) => {
	return axios({
		url: baseURL + "/customdata/user-permission/rolePermissionDataList",
		method: "post",
		headers: {
			Authorization: localStorage.getItem('token'),
		},
		data: data
	})
}

// API-51修改用户密码
export let updatePassword = (data) => {
	return axios({
		url: baseURL + "/customdata/user-system/updatePassword",
		method: "post",
		headers: {
			Authorization: localStorage.getItem('token'),
		},
		data: data
	})
}


// APP2.0版本新加的接口


// API-52活动列表
export const activityList = (data) => {
	return axios({
		url: baseURL + "/activity/manage/activityList",
		method: "POST",
		headers: {
			Authorization: localStorage.getItem('token'),
		},
		data: data
	})
}

// API-53修改活动
export const updateActivity = (data) => {
	return axios({
		url: baseURL + "/activity/manage/updateActivity",
		method: "POST",
		headers: {
			Authorization: localStorage.getItem('token'),
		},
		data: data
	})
}

// API-54删除活动
export const deleteActivity = (id) => {
	return axios({
		url: baseURL + `/activity/manage/deleteActivity?id=${id}`,
		method: "get",
		headers: {
			Authorization: localStorage.getItem('token'),
		},
	})
}

// API-55新增活动
export const addActivity = (data) => {
	return axios({
		url: baseURL + "/activity/manage/addActivity",
		method: "POST",
		headers: {
			Authorization: localStorage.getItem('token'),
		},
		data: data
	})
}


// API-56活动类型列表
export const activityType = (data) => {
	return axios({
		url: baseURL + `/classify/manage/activityType?page=${data.page}&title=${data.title}`,
		method: "get",
		headers: {
			Authorization: localStorage.getItem('token'),
		},
	})
}


// API-57活动分类列表
export const activityClassify = (data) => {
	return axios({
		url: baseURL + `/classify/manage/activityClassify?page=${data.page}&title=${data.title}`,
		method: "get",
		headers: {
			Authorization: localStorage.getItem('token'),
		},
	})
}

// API-58删除活动分类列表或者活动类型
export const delClassifyType = (id) => {
	return axios({
		url: baseURL + `/classify/manage/delClassifyType?id=${id}`,
		method: "get",
		headers: {
			Authorization: localStorage.getItem('token'),
		},
	})
}


// API-59修改活动类型图标
export const updateTypeImg = (data) => {
	return axios({
		url: baseURL + "/classify/manage/updateTypeImg",
		method: "POST",
		headers: {
			Authorization: localStorage.getItem('token'),
		},
		data: data
	})
}

// API-60修改活动类型名称
export const updateType = (data) => {
	return axios({
		url: baseURL + "/classify/manage/updateType",
		method: "POST",
		headers: {
			Authorization: localStorage.getItem('token'),
		},
		data: data
	})
}

// API-61新增活动类型
export const addType = (data) => {
	return axios({
		url: baseURL + "/classify/manage/addType",
		method: "POST",
		headers: {
			Authorization: localStorage.getItem('token'),
		},
		data: data
	})
}


// API-62修改活动分类
export const updateClassify = (data) => {
	return axios({
		url: baseURL + "/classify/manage/updateClassify",
		method: "POST",
		headers: {
			Authorization: localStorage.getItem('token'),
		},
		data: data
	})
}


// API-63新增活动分类
export const addClassify = (data) => {
	return axios({
		url: baseURL + "/classify/manage/addClassify",
		method: "POST",
		headers: {
			Authorization: localStorage.getItem('token'),
		},
		data: data
	})
}


// API-64新增活动图片
export const addTypeIcon = (data) => {
	return axios({
		url: baseURL + "/activity/manage/addTypeIcon",
		method: "POST",
		headers: {
			Authorization: localStorage.getItem('token'),
		},
		data: data
	})
}

// API-65删除活动图片
export const delTypeIcon = (data) => {
	return axios({
		url: baseURL + "/activity/manage/delTypeIcon",
		method: "POST",
		headers: {
			Authorization: localStorage.getItem('token'),
		},
		data: data
	})
}


// API-66新增活动详情图片
export const detailsImg = (data) => {
	return axios({
		url: baseURL + "/activity/manage/detailsImg",
		method: "POST",
		headers: {
			Authorization: localStorage.getItem('token'),
		},
		data: data
	})
}

// API-67删除活动详情图片
export const delDetailsImg = (data) => {
	return axios({
		url: baseURL + "/activity/manage/delDetailsImg",
		method: "POST",
		headers: {
			Authorization: localStorage.getItem('token'),
		},
		data: data
	})
}


// API-68活动分类不翻页
export const ClassifyNoPage = () => {
	return axios({
		url: baseURL + `/classify/manage/ClassifyNoPage`,
		method: "get",
		headers: {
			Authorization: localStorage.getItem('token'),
		},
	})
}

// API-68活动类型不翻页
export const TypeNoPage = () => {
	return axios({
		url: baseURL + `/classify/manage/TypeNoPage`,
		method: "get",
		headers: {
			Authorization: localStorage.getItem('token'),
		},
	})
}

// API-69线路分类列表
export const lineClassify = (data) => {
	return axios({
		url: baseURL + `/classify/manage/lineClassify?page=${data.page}&title=${data.title}`,
		method: "get",
		headers: {
			Authorization: localStorage.getItem('token'),
		},
	})
}

// API-70线路分类列表不翻页
export const lineClassifyNoPage = () => {
	return axios({
		url: baseURL + `/classify/manage/lineClassifyNoPage`,
		method: "get",
		headers: {
			Authorization: localStorage.getItem('token'),
		},
	})
}

// API-71修改线路分类
export const updLine = (data) => {
	return axios({
		url: baseURL + `/classify/manage/updLine`,
		method: "POST",
		headers: {
			Authorization: localStorage.getItem('token'),
		},
		data: data
	})
}

// API-72删除线路分类
export const delLine = (id) => {
	return axios({
		url: baseURL + `/classify/manage/delLine?id=${id}`,
		method: "get",
		headers: {
			Authorization: localStorage.getItem('token'),
		},
	})
}

// API-73修改线路分类
export const addLineClassify = (data) => {
	return axios({
		url: baseURL + `/classify/manage/addLineClassify`,
		method: "POST",
		headers: {
			Authorization: localStorage.getItem('token'),
		},
		data: data
	})
}

// API-74线路列表
export const lineList = (data) => {
	return axios({
		url: baseURL + `/line/manage/lineList`,
		method: "POST",
		headers: {
			Authorization: localStorage.getItem('token'),
		},
		data: data
	})
}

// API-75线路修改
export const updateLine = (data) => {
	return axios({
		url: baseURL + `/line/manage/updLine`,
		method: "POST",
		headers: {
			Authorization: localStorage.getItem('token'),
		},
		data: data
	})
}


// API-76新增线路
export const addLine = (data) => {
	return axios({
		url: baseURL + `/line/manage/addLine`,
		method: "POST",
		headers: {
			Authorization: localStorage.getItem('token'),
		},
		data: data
	})
}


// API-77删除线路
export const delLineList = (id) => {
	return axios({
		url: baseURL + `/line/manage/delLine?id=${id}`,
		method: "get",
		headers: {
			Authorization: localStorage.getItem('token'),
		},
	})
}

// API-78删除线路背景图
export const delImg = (data) => {
	return axios({
		url: baseURL + `/line/manage/delImg`,
		method: "POST",
		headers: {
			Authorization: localStorage.getItem('token'),
		},
		data: data
	})
}

// API-79添加线路背景图
export const addImg = (data) => {
	return axios({
		url: baseURL + `/line/manage/addImg`,
		method: "POST",
		headers: {
			Authorization: localStorage.getItem('token'),
		},
		data: data
	})
}

// API-80线路套餐列表
export const selPackage = (id) => {
	return axios({
		url: baseURL + `/line/manage/selPackage?id=${id}`,
		method: "get",
		headers: {
			Authorization: localStorage.getItem('token'),
		},
	})
}


// API-81添加线路套餐
export const addPackage = (data) => {
	return axios({
		url: baseURL + `/line/manage/addPackage`,
		method: "POST",
		headers: {
			Authorization: localStorage.getItem('token'),
		},
		data: data
	})
}

// API-82删除线路套餐
export const delPackage = (id) => {
	return axios({
		url: baseURL + `/line/manage/delPackage?id=${id}`,
		method: "get",
		headers: {
			Authorization: localStorage.getItem('token'),
		},
	})
}

// API-83修改线路套餐
export const updPackage = (data) => {
	return axios({
		url: baseURL + `/line/manage/updPackage`,
		method: "POST",
		headers: {
			Authorization: localStorage.getItem('token'),
		},
		data: data
	})
}

// API-84添加线路详情图片
export const addDetails = (data) => {
	return axios({
		url: baseURL + `/line/manage/addDetails`,
		method: "POST",
		headers: {
			Authorization: localStorage.getItem('token'),
		},
		data: data
	})
}

// API-85删除线路详情图片
export const delDetails = (data) => {
	return axios({
		url: baseURL + `/line/manage/delDetails`,
		method: "POST",
		headers: {
			Authorization: localStorage.getItem('token'),
		},
		data: data
	})
}

// API-86查询线路日期列表
export const selDate = (id) => {
	return axios({
		url: baseURL + `/line/manage/selDate?id=${id}`,
		method: "get",
		headers: {
			Authorization: localStorage.getItem('token'),
		},
	})
}

// API-87添加线路套餐对应日期
export const addDate = (data) => {
	return axios({
		url: baseURL + `/line/manage/addDate`,
		method: "POST",
		headers: {
			Authorization: localStorage.getItem('token'),
		},
		data: data
	})
}

// API-88删除线路日期
export const delDate = (id) => {
	return axios({
		url: baseURL + `/line/manage/delDate?id=${id}`,
		method: "get",
		headers: {
			Authorization: localStorage.getItem('token'),
		},
	})
}

// API-89修改线路套餐对应日期
export const updDate = (data) => {
	return axios({
		url: baseURL + `/line/manage/updDate`,
		method: "POST",
		headers: {
			Authorization: localStorage.getItem('token'),
		},
		data: data
	})
}