import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
// export let baseURL = "https://www.cqtxj.club:10004/api"
// export let baseURL = "http://www.cqtxj.club:10004/api"
export let baseURL = "https://admin.txj.xyz/fxtx/app"
// export let baseURL = "http://192.168.1.39:10013/fxtx/app"
// export let uploadURL = "http://www.cqtxj.club:10002/fxtx/file/uploadAndScan"
export let uploadURL = "https://admin.txj.xyz/fxtx/file/uploadOss?uid="
export let imgUrl = "http://www.cqtxj.club:10002/fxtx/file/preview/"
// 引入axios
import axios from 'axios';
import VueAxios from 'vue-axios';
Vue.use(VueAxios, axios)
Vue.config.productionTip = false
new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')