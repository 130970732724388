import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/login.vue'

Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'login',
		component: Login
	},

	{
		path: '/admin',
		name: 'admin',
		component: () => import('../views/Admin.vue'),
		redirect: '/admin/index',
		children: [{
				path: 'index',
				name: 'index',
				component: () => import('../admin/Index.vue'),
			},
			{
				path: 'outdoor',
				name: 'outdoor',
				component: () => import('../admin/OutDoor.vue'),
			},

			{
				path: 'dynamic',
				name: 'dynamic',
				component: () => import('../admin/dynamicComment.vue'),
			},
			{
				path: 'road',
				name: 'road',
				component: () => import('../admin/roadComment.vue'),
			},
			{
				path: 'Userinfo',
				name: 'Userinfo',
				component: () => import('../admin/Userinfo.vue'),
			},
			{
				path: 'user',
				name: 'user',
				component: () => import('../admin/User.vue'),
			},
			{
				path: 'apply',
				name: 'apply',
				component: () => import('../admin/applyActivity.vue'),
			},
			{
				path: 'draw',
				name: 'draw',
				component: () => import('../admin/drawActivity.vue'),
			},
			{
				path: 'vote',
				name: 'vote',
				component: () => import('../admin/voteActivty.vue'),
			},
			{
				path: 'classify',
				name: 'classify',
				component: () => import('../admin/Classify.vue'),
			},
			{
				path: 'role',
				name: 'role',
				component: () => import('../admin/Role.vue'),
			},
			{
				path: 'adminUser',
				name: 'adminUser',
				component: () => import('../admin/AdminUser.vue'),
			},
			{
				path: 'applyClassify',
				name: 'applyClassify',
				component: () => import("../admin/applyClassify.vue"),
			},
			{
				path: 'applyType',
				name: 'applyType',
				component: () => import("../admin/applyType.vue"),
			},
			{
				path: 'lineClassify',
				name: 'lineClassify',
				component: () => import("../admin/lineClassify.vue"),
			},
			{
				path: 'line',
				name: 'line',
				component: () => import("../admin/line.vue"),
			}
		]
	},
]

const router = new VueRouter({
	// mode: 'history',
	mode: 'hash',
	base: process.env.BASE_URL,
	routes
})

export default router