<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
* {
  padding: 0;
  margin: 0;
  user-select: none;
  text-decoration: none;
  box-sizing: border-box;
}
</style>
