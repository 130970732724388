<template>
	<div class="top">
		<!-- 背景图片区域 -->
		<div class="xhbg">
			<div class="xhcon">
				<div class="xhua xhua1"></div>
				<div class="xhua xhua1 xuhua11"></div>
				<div class="xhua xhua2"></div>
				<div class="xhua xhua2 xuhua22"></div>
				<div class="xhua xhua3"></div>
				<div class="xhua xhua3 xuhua33"></div>
			</div>
		</div>

		<div id="logins">
			<h1>风行天下管理员登录</h1>
			<el-form :label-position="labelPosition" label-width="80px" :model="formLabelAlign">
				<el-form-item label="用户名">
					<el-input v-model="formLabelAlign.name"></el-input>
				</el-form-item>
				<el-form-item label=" 密码">
					<el-input v-model="formLabelAlign.password" type="password"></el-input>
				</el-form-item>
				<el-button type="primary" @click="logindata">立即登录</el-button>
			</el-form>
		</div>
	</div>
</template>

<script>
	import {
		Login
	} from "../api/admin";
	import {
		sha256
	} from "../utils/sha256";
	export default {
		data() {
			return {
				labelPosition: "right",
				formLabelAlign: {
					name: "",
					password: "",
				},
			};
		},

		methods: {
			logindata() {
				let password = sha256(
					this.formLabelAlign.password + "sW9FizZdjo9LMQk2R68Gj9Zd1351234auto"
				);
				Login({
					userName: this.formLabelAlign.name,
					password: password,
				}).then((res) => {
					console.log(res.data);
					if (res.data.code == 200) {
						localStorage.setItem("token", res.data.data.Authorization);
						localStorage.setItem("uid", res.data.data.user.id);
						this.$router.push("/admin");

					} else {
						alert(res.data.message)
					}
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	.top {
		position: relative;
		display: flex;
		justify-content: center;
	}

	#logins {
		position: absolute;
		width: 540px;
		height: 300px;
		transform: translateY(80%);
		background-color: #ffffff;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		border-radius: 4px;
		opacity: 0.9;

		h1 {
			margin-top: 20px;
			margin-bottom: 60px;
			color: gray;
		}

		.el-form {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			padding-right: 30px;

			.el-button {
				margin-left: 5px;
			}

			.el-input {
				// margin-right: 120px !important;
				width: 300px;
			}
		}
	}
</style>
<style scoped>
	.xhbg {
		background: url("../../public/img/24.jpg") no-repeat 0px 0px;
		min-height: 1000px;
		z-index: -10;
		width: 100%;
		height: 1000px;
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0.83;
		background-size: 100% 100%;
	}

	.xhcon {
		position: absolute;
		height: 900px;
		width: 100%;
		max-width: 100%;
		top: 0;
		overflow: hidden;
		z-index: 2;
		pointer-events: none;
	}

	.xhua {
		display: block;
		position: absolute;
		z-index: 2;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		transform: translate3d(0, -100%, 0);
		animation: snow linear infinite;
	}

	.xhua1 {
		background-image: url("../../public/img/xuhua1.png");
		animation-duration: 15s;
	}

	.xuhua11 {
		background-image: url("../../public/img/xuhua1.png");
		animation-delay: 7.5s;
	}

	.xhua2 {
		background-image: url("../../public/img/xuhua2.png");
		animation-duration: 20s;
	}

	.xuhua22 {
		background-image: url("../../public/img/xuhua2.png");
		animation-delay: 10s;
	}

	.xhua3 {
		background-image: url("../../public/img/hua3.png");
		animation-duration: 30s;
	}

	.xuhua33 {
		background-image: url("../../public/img/hua3.png");
		animation-delay: 15s;
	}

	@keyframes snow {
		0% {
			transform: translate3d(0, -100%, 0);
		}

		100% {
			transform: translate3d(15%, 100%, 0);
		}
	}
</style>